import * as React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import parse from "html-react-parser"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import TopSub from "../components/top-sub"
import Container from "../atomic/partials/container"
import { color } from "../components/colors"
import ArticleBox from "../components/article-box"

const Wrapper = styled.div`
  padding-top: 32px;
`

const Path = styled.div`
  display: flex;
  margin-bottom: 64px;
  font-weight: 400;

  a {
    text-transform: uppercase;
    text-decoration: none;
    transition: color 0.3s ease;
    color: #000;
    margin-right: 6px;

    &:hover {
      color: ${color.primary};
    }
  }
`

const BoxesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 60px 30px;
  width: 100%;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`

const Box = styled.div`
  width: 100%;
  padding: 40px 15px;
  border-width: 5px 0 2px;
  border-color: ${color.blueLight};
  border-style: solid;

  h2 {
    margin-bottom: 20px;
  }

  p {
    font-weight: 400;
    margin-bottom: 32px;
  }
`

const Inwestycje = ({ data }) => (
  <Layout>
    <Seo />
    <TopSub />
    <Container>
      <Wrapper>
        <Path>
          <Link to="/">SM TARAS PÓŁNOC</Link> / INWESTYCJE
        </Path>
        {/* <BoxesWrapper> */}
        {data.allWpPost.nodes.length > 0 ? (
          data.allWpPost.nodes.map((article, i) => (
            <ArticleBox article={article} url="inwestycje" />
          ))
        ) : (
          <Box>
            <h2>Brak ogłoszeń</h2>
          </Box>
        )}
        {/* </BoxesWrapper> */}
      </Wrapper>
    </Container>
  </Layout>
)

export default Inwestycje

export const pageQuery = graphql`
  query WordpressInwestycje {
    allWpPost(
      filter: {
        categories: { nodes: { elemMatch: { slug: { eq: "inwestycje" } } } }
      }
    ) {
      nodes {
        excerpt
        title
        slug
        date(formatString: "YYYY MMMM DD", locale: "PL")
      }
    }
  }
`
